


import { Message } from "@/models/userMessage.model";
import { borrowerData } from "@/models/borrowerData.model";
import { BorrowerInformation } from "@/models/pos/borrower-info.model";
import { LoanAndProperty } from "@/models/pos/loan-and-property.model";
import { Declaration } from "@/models/pos/declaration.model";
import { DemographicInformation } from "@/models/pos/demographic-info.model";
import { FinancialInfo } from "@/models/pos/financial-info.model";
import { Acknowledgement } from "@/models/pos/acknowledgement.model";
import { LoanOriginatorInfo } from "@/models/pos/loan-originator-info.model";
import { TransactionDetail } from "@/models/pos/transaction-detail.model";
import saveAs from "save-as";
import { Component, Vue } from "vue-property-decorator";
import { mixins } from "vue-class-component";
import {BASE_API_URL, EventBus} from "@/config";
import Axios from "axios";
import { HollowDotsSpinner } from "epic-spinners";
import TextEditor from "@/views/TextEditor.vue";

import CommonMixin from "@/mixins/common.mixin";
import { VueEditor } from "vue2-editor";
import vueCustomScrollbar from "vue-custom-scrollbar";
import DisplayFiles from "@/views/DisplayDocument.vue";
import OverlayInput from "@/views/OverlayInput.vue";
import SelectBorrowerForPos from "@/views/SelectBorrowerForPos.vue";
import { ALLOWED_FILE_EXTENSIONS } from "@/constants";
import Popover from "vue-js-popover";
import ViewDocument from "@/views/ViewDocument.vue";
import _ from "lodash";

Vue.use(Popover);

@Component({
  components: {
    HollowDotsSpinner,
    VueEditor,
    vueCustomScrollbar,
    DisplayFiles,
    SelectBorrowerForPos,
    OverlayInput,
    ViewDocument,
    TextEditor,
  }
})
export default class PipelineComponent extends mixins(CommonMixin) {
  public allowedFileExtensions = ALLOWED_FILE_EXTENSIONS;
  public borrowerData = new borrowerData();
  public borrowerInformation = new BorrowerInformation();
  public loanAndProperty = new LoanAndProperty();
  public declaration = new Declaration();
  public demographicInformation = new DemographicInformation();
  public financialInfo = new FinancialInfo();
  public acknowledgement = new Acknowledgement();
  public loanOriginatorInfo = new LoanOriginatorInfo();
  public transactionDetail = new TransactionDetail();
  public conditionType = null;
  public borrower: any = null;
  public loanTxnId: any = null;
  public communicationdotLoader = false;
  public uploadedOtherDocs = [];
  public fileToDisplay = null;
  public dotLoader = false;
  public playingAudioIndex = null;
  public finalPlayingAudioIndex = null;
  public allDocuments = [];
  public requiredDocuments = [];
  public horizontalProgressLineData = [];
  public currentComment: any = null;
  public currentStatusId: any = null;
  public currentStatusName: any = null;
  public currentAttachs: any = null;
  public pipeLineHeaderData = null;
  public selectedProcessor = null;
  public updatedDate = null;
  public openBorrowerSelectionModal = false;
  public pendingSubLoader = true;
  public showDocuments = true;
  public approvedDocuments = [];
  public batchToLenderDocs = [];
  public pendingSubmissionDocs = [];
  public brokerDisclosuresDocuments = [];
  public brokerDisclosuresDocumentsStatus = null;
  public additionalDocuments = [];
  public approvedAdditionalDocuments = [];
  public approvedBrokerDisclosuresDocuments = [];
  public showBrokerDisclosuresDocuments: boolean = false;
  public downloadingBrokerDisclosure: boolean = false;
  lookingAtDocument = null;
  everyBrokerDisclosureIsSigned: boolean = false;
  public fileForDelete = {};
  public fileInput = null;
  public searchablePendingConditions: any = null;
  public searchablePendingSubmission: any = null;
  public searchableSubmittedToLender: any = null;
  public searchableCommunicationHistory: any = null;
  public visibleInputId:string = null;
  public enterTargetRow:object = null;

  get userType() {
    return this.$store.state.sessionObject.type;
  }

  public addMessage(type) {
    this.sendMessage({
      toUserType: "Wemlo Processor",
      borrowerName:
        this.pipeLineHeaderData.borrowerInfo[
          this.pipeLineHeaderData.primaryBorrowerIndex
        ].firstName +
        " " +
        this.pipeLineHeaderData.borrowerInfo[
          this.pipeLineHeaderData.primaryBorrowerIndex
        ].lastName,
      loanTxnId: this.pipeLineHeaderData.loanTxnId,
      toUserId:
        this.selectedProcessor != null
          ? this.selectedProcessor.processorId
          : null
    });
  }

  public getDocumentName(doc){
      if (doc && doc.borrowerName) {
          return (`${doc.borrowerName} - ${doc.annotatedCategoryName || doc.categoryName}`);
      } else if (doc && doc.categoryName) {
        return (`${doc.annotatedCategoryName || doc.categoryName}`);
      } else {
        return "";
      }
  }

  public data() {
    return {
      customToolBar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }]
      ]
    };
  }

  // Pending conditions:
  public get filteredBrokerDisclosuresDocuments() {
    return this.getFilteredDocs(this.brokerDisclosuresDocuments, this.searchablePendingConditions)
  }

  public get filteredRequiredDocuments() {
    return this.getFilteredDocs(this.requiredDocuments, this.searchablePendingConditions)
  }

  public get filteredAdditionalDocuments() {
    return this.getFilteredDocs(this.additionalDocuments, this.searchablePendingConditions)
  }

  // Pending Submission
  public get filteredPendingSubmissionDocs() {
    return this.getFilteredDocs(this.pendingSubmissionDocs, this.searchablePendingSubmission)
  }

  public get filteredApprovedBrokerDisclosuresDocuments() {
    return this.getFilteredDocs(this.approvedBrokerDisclosuresDocuments, this.searchablePendingSubmission)
  }

  // Pending Submission
  public get filteredBatchToLenderDocs() {
    return this.getFilteredDocs(this.batchToLenderDocs, this.searchableSubmittedToLender)
  }

  public getFilteredDocs(docs, searchable) {
    return searchable ? docs.filter(doc => doc.categoryName?.toLowerCase().includes(searchable?.toLowerCase())) : docs
  }

  public get communicationHistory() {
    return this.borrower.borrowerData.communicationHistory.map(callLog => {
      return {
        ...callLog,
        toUserName: callLog.to?.length ? this.getCommunicationToUserName(callLog.to) : '-',
      }
    })
  }

  public get filteredCommunicationHistory() {
    const searchable = this.searchableCommunicationHistory?.toLowerCase()
    const history = this.communicationHistory
    return searchable ? history.filter(h => h.body?.toLowerCase().includes(searchable)) : history
  }

  public async getBorrowerLoanDetails() {
    try {
      this.communicationdotLoader = true;
      let response = await Axios.post(
        BASE_API_URL + "broker/getBorrowerLoanDetails",
        {
          loanTxnId: this.loanTxnId,
          type: this.userType
        });

      if (response.data.inActiveArchived) {
        this.$snotify.info(response.data.message);
        await this.$router.push(this.$getCurrentUserTypePath("dashboard"));
      }
      this.borrower = response.data;
      this.pipeLineHeaderData = this.assignPipelineHeaderData(
        this.borrower.pipelineData,
        false
      );

      this.allDocuments = _.get(response, 'data.borrowerData.documents.documents', []);
      this.allDocuments = this.allDocuments.filter(document => document?.categoryName !== 'Loan Estimate');

      this.setRequiredDocuments(this.allDocuments);

      this.communicationdotLoader = false;
    } catch (error) {
      this.communicationdotLoader = false;
      console.log(error);
    }
  }

  public setRequiredDocuments(documents) {
    this.requiredDocuments = [];

    /**
     * We only show these documents in the pending conditions section
     * If they are:
     *  1 - documentStatus !== 'Not Applicable'
     *  2 - Doesn't have a fileHistory
     *  3 - Or if the latest file is rejected
     */
    this.requiredDocuments =
      documents
        .filter(document => document?.documentStatus !== 'Not Applicable')
        .filter(document => {
          const fileHistory = (document?.fileHistory || []);
          const lastFile:any = _.last(fileHistory);

          if (!fileHistory.length) {
            return true;
          }

          if (lastFile?.status === 'Rejected') {
            return true;
          }

          return false;
        });

    this.sortRequiredDocs();
  }

  public sortRequiredDocs() {
    this.requiredDocuments.sort((a, b) => {
      if (a._id === b._id) return 0;
      return a._id > b._id ? -1 : 1;
    });
  }

  public dateInProperFormat(date) {
    let event = new Date(date);
    let day = event.getDate() < 10 ? "0" + event.getDate() : event.getDate();
    let month =
      event.getMonth() < 10 ? "0" + event.getMonth() : event.getMonth();
    let year = event.getFullYear();
    return day + "-" + month + "-" + year;
  }

  public timeInLocalTimeString(date) {
    let event = new Date(date);
    return event.toLocaleTimeString("en-US");
  }

  public selectFiles(event) {
    try {
      if (event.target.files.length == 0) return;
      Object.keys(event.target.files).forEach((element, i) => {
        this.uploadedOtherDocs.push(event.target.files[element]);
      });
    } catch (error) {
      console.log(error.response);
    }
  }

  public checkDocumentHasSample(doc) {
    let returnValue = false;
    if (doc.samples.length > 0) {
      returnValue = true;
    }
    return returnValue;
  }

  public popup_comment_image(data) {
    this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](null, data);
  }

  public displayDocuments(fileIndex, documentIndex, check, origin) {
    let comment = null;
    let commentAttachs = null;
    let commentData = null;

    if (this.borrower.borrowerData.comment) {
      commentData = this.borrower.borrowerData.comment[
        this[origin][documentIndex].fileHistory[fileIndex].commentPath
      ];
    }

    if (commentData) {
      comment = commentData.comment;
      commentAttachs =
        commentData.attachments.length > 0 ? commentData.attachments : null;
    }
    let files = this[origin][documentIndex].fileHistory[fileIndex].files;
    this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
      comment,
      files,
      commentAttachs
    );
  }

  public showThreeFileHistory(fileHistory) {
    let length = fileHistory.length;
    if (length > 3) {
      return fileHistory.slice(length - 3, length);
    }
    return fileHistory;
  }

  displayDocumentNew(document) {
    if (!document) { return; }

    this.lookingAtDocument = document;
    this.$modal.show('viewDocumentModal');
  }

  closingViewDocument() {
    this.$modal.hide('viewDocumentModal');
    this.lookingAtDocument = null;
  }

  async reopenViewDocument() {
    this.$modal.hide('viewDocumentModal');
    await this.getWesignRequiredDocuments();

    const currentViewingDocument = _.get(this.lookingAtDocument, '_id', null);
    const disclosures = [ ...this.approvedBrokerDisclosuresDocuments, ...this.brokerDisclosuresDocuments ];
    const document = disclosures.find(disclosure => _.get(disclosure, '_id', undefined) === currentViewingDocument);

    if (!currentViewingDocument || !document) {
      this.lookingAtDocument = null;
      return;
    }

    this.lookingAtDocument = document;
    this.$modal.show('viewDocumentModal');
  }

  public displaySampleDocument(files) {
    this.$refs.dfl["setInitialValuesForShowingSamplesWithHeading"](
      "View Document",
      files,
      null
    );
  }

  public async uploadDocuments(event, docs) {
    this.$store.state.wemloLoader = true;
    try {
      if (event.target.files.length == 0) {
        this.$store.state.wemloLoader = false;
        return;
      }
      let files = [];
      Object.values(event.target.files).forEach(f => {
        files.push(f);
      });
      let path = "Borrower/" + this.$route.query.id + "/LoanDocuments/";
      let formData = new FormData();
      formData.append("path", JSON.stringify(path));
      files.forEach(f => {
        formData.append("file", f);
      });
      let response = await Axios.post(
        BASE_API_URL + "wemloprocessor/getFileUploadedPath",
        formData);
      let filePath = response.data;
      let obj = {
        commentPath: null,
        status: "Uploaded",
        uploadedOn: new Date(),
        uploadedBy: this.$userId,
        files: filePath
      };
      docs.updatedOn = new Date();
      docs.fileHistory.push(obj);
      await this.uploadLoanDocuments(docs);
      this.$store.state.wemloLoader = false;
    } catch (error) {
      this.$store.state.wemloLoader = false;
      console.log(error);
    }
  }

  public async uploadLoanDocuments(docs) {
    try {
      let obj = {
        loanTxnId: this.$route.query.id,
        docs: docs,
        userId: this.$userId
      };
      await Axios.post(
        BASE_API_URL + "broker/saveLoanDocuments",
        obj);
      await this.getBorrowerLoanDetails();
      this.$snotify.success("Document Uploaded Successfully");
    } catch (error) {
      console.log(error);
    }
  }

  public fileExtension(fileName) {
    let ext = fileName.substr(fileName.lastIndexOf(".") + 1);
    return ext.toLowerCase();
  }

  public async playAudio(index, event) {
    if (this.playingAudioIndex != null) {
      let audio = this.$refs["aaudio" + this.playingAudioIndex];
      this.finalPlayingAudioIndex = index;
      audio[0].pause();
    } else {
      this.playingAudioIndex = index;
    }
  }

  public stopPlayingAudio(index, event) {
    if (this.finalPlayingAudioIndex != null) {
      this.playingAudioIndex = this.finalPlayingAudioIndex;
      this.finalPlayingAudioIndex = null;
    } else {
      this.playingAudioIndex = null;
    }
  }

  public displaySmsDetails(data) {
    if (data.body && data.attachments.length > 0) {
      this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
        data.body,
        data.attachments
      );
    } else {
      this.$refs.dfl["setInitialValuesForShowMessage"](data.body, null);
    }
  }

  public async setLoanProgressStatusLine() {
    this.horizontalProgressLineData = [];
    let currentStatuses = this.borrower.borrowerData.loanAllStatus;
    currentStatuses = currentStatuses.filter((s, i) => s.timeline);
    currentStatuses.forEach(status => {
      if (this.filterStatus(status)) {
        let comment = null;
        let att = null;
        if (status.commentIndex != null) {
          if (status.isAdditionalTask || status.statusId == 22){
          comment = this.borrower.borrowerData.comment[status.commentIndex].comment;
          att = this.borrower.borrowerData.comment[status.commentIndex].attachments;
          } else {
          comment = status.commentIndex?this.borrower.borrowerData.comment.find(comment => comment._id.toString() == status.commentIndex.toString()).comment:''
          att = status.commentIndex?this.borrower.borrowerData.comment.find(comment => comment._id.toString() == status.commentIndex.toString()).attachments:''
          }
        }
        let obj = {
          statusId: status.statusId,
          _id: status._id,
          taskId: status.taskId,
          taskRef: status.taskRef,
          additionalStatus: status.additionalStatus,
          name: status.name,
          comment: comment,
          updatedOn: status.updatedOn,
          count: status.count,
          attachments: att,
          conditionName: status.conditionName,
          class: status.statusCompleted ? "current" : "not-current"
        };

        this.horizontalProgressLineData.push(obj);
      }
    });

    /**
     * check the Run-Aus N/A is statusCompleted and Run-Aus approved is not statusCompleted,
     *  based on Run-Aus approved replaced with Run-Aus N/A
     */
    let notApplicable = currentStatuses.find(a => a.statusId == 9 && a.statusCompleted);
    let approved = currentStatuses.find(a => a.statusId == 8 && a.statusCompleted);
    if (notApplicable && !approved) {
      let index = this.horizontalProgressLineData.findIndex(a => a.statusId == 8);
      let comment = null;
      let attachments =null
      if (notApplicable.commentIndex != null) {
       comment = this.borrower.borrowerData.comment.find(comment => comment._id.toString() == notApplicable.commentIndex.toString()).comment
         attachments = this.borrower.borrowerData.comment.find(comment => comment._id.toString() == notApplicable.commentIndex.toString()).attachments
      }
      if (index != -1) {
        let obj = {
          statusId: 9,
          name: notApplicable.name,
          comment: comment,
          attachments:attachments,
          class: notApplicable.statusCompleted ? "current" : "not-current"
        };
        this.horizontalProgressLineData.splice(index, 1, obj);
      }
    }
  }

  public filterStatus(status) {
    switch (status.statusId) {
      case 7:
        return status.statusCompleted ? true : false;
      case 12:
        return status.statusCompleted ? true : false;
      case 9:
        return false;
      default:
        return true;
    }
  }

  public checkReleaseDate() {
    let releasedDate = new Date("2020-03-13");
    let borrowerCreatedDate = new Date(this.borrower.pipelineData.createdOn);
    return borrowerCreatedDate > releasedDate;
  }

  public getTimeZone(date) {
    let zone = new Date(date)
      .toLocaleTimeString("en-us", { timeZoneName: "short" })
      .split(" ")[2]
      .slice(0, 3);
    return zone;
  }

  public enableTransparentOverlay() {
    let overlay: any = this.$refs.transparent_overlay;
    if (overlay.style.display == "block") {
      overlay.style.display = "none";
    } else {
      overlay.style.display = "block";
    }
  }

  public showPendingConditions(name) {
    this.$modal.show("modalForPendingConditions");
    this.conditionType = name;
  }

  public showAttachedFiles(index) {
    this.$modal.show('toSeeAttachment', {
      type: 'outgoing',
      file: this.file,
      currentFileIndex: index,
    })
  }

  public deleteDoc(from) {
    try {
      this[from.deleteData].splice(from.index, 1);
    } catch (error) {
      console.log(error);
    }
  }

  /*********************************************************************************************************************************************************************
   *                                                          Select Borrower for POS Flow                                                                             *
   *********************************************************************************************************************************************************************/

  public selectPosBorrower() {
    if (this.borrower) {
      if (this.borrower.borrowerData.relationshipMapping.length == 1) {
        this.$router.push({
          path: this.$getCurrentUserTypePath("pos-questionnaire"),
          query: {
            loanTxnId: this.loanTxnId,
            borrowerId: this.borrower.pipelineData.borrowerInfo[
              this.borrower.borrowerData.relationshipMapping[0].borrowerIndex
            ].userId
          }
        });
      } else {
        this.openBorrowerSelectionModal = true;
      }
    }
  }

  /**************************************************************************************************
   *                                    IF DOCUMENT NOT LOADED THEN                                  *
   **************************************************************************************************/
  public ifDocumentNotLoad(time: number) {
    this.dotLoader = true;
    try {
      setTimeout(() => {
        let checkLoader = document.getElementById("checkLoader");
        if (checkLoader != null) {
          let iframe: any = document.getElementById("checkElement");
          if (iframe.localName == "iframe") {
            iframe.src =
              "https://docs.google.com/gview?url=" +
              this.fileToDisplay +
              "&embedded=true";
          } else {
            iframe.src = this.fileToDisplay;
          }
          this.ifDocumentNotLoad(time + 1000);
        }
      }, time);
    } catch (error) {
      console.log(error);
      this.dotLoader = false;
    }
  }
  public getCommunicationToUserName(users) {
    let userArray = [];
    users.forEach(element => {
      userArray.push(element.toName);
    });
    return userArray.toString();
  }


  /**
   * For borrower not fetching the
   * progress bar comes below header
   */
  async fetchBorrowerProgressTimeline(loanTxnId){
    try {
      let manualTitleComment = "Automated title order sent via email to title company";
      this.communicationdotLoader = true;
      this.horizontalProgressLineData = [];
      let response = await Axios.post(BASE_API_URL + 'broker/fetchBorrowerProgressTimeline', { loanTxnId: loanTxnId });
      this.horizontalProgressLineData = response.data;

      //Show comment based on default/manual added title with comments
       this.horizontalProgressLineData.forEach(timeline => {
         if(timeline.name == "Title Ordered" && this.borrower.pipelineData.isTitleAddedManually){
          if(timeline.class !='not-current' && this.borrower.pipelineData.isTitleAddedManually){
             if(this.borrower.borrowerData.orderTitle) manualTitleComment = 'Title order has been confirmed.';
            timeline.comment =  timeline.comment || manualTitleComment;
          }
         }else if(timeline.name == "Title Ordered" && !this.borrower.pipelineData.isTitleAddedManually){
           if(this.borrower.pipelineData.titleOrderedId && this.borrower.borrowerData.orderTitle){
            timeline.comment = `Automated title order sent to integrated title company. Confirmation: ${this.borrower.pipelineData.titleOrderedId}`;
          }
          if(!this.borrower.pipelineData.titleOrderedId && this.borrower.borrowerData.orderTitle) {
            timeline.comment = `Title order has been confirmed.`;
          }
         }
       })
      this.communicationdotLoader = false;
    } catch (error) {
      console.log(error);
    }
  }

  enablePopoverInTimeline(status){
    this.currentAttachs=status.attachments;
    this.currentStatusId = status.id;
    this.currentStatusName = status.name;
    this.currentComment=status.comment;
    this.updatedDate=status.completedOn;
    if (this.currentStatusName === 'Initial Disclosure Sent') {
        this.currentComment = 'Fee Worksheet was approved by Broker when loan was submitted to Wemlo for processing.';
    }
    this.enableTransparentOverlay();
  }

  public async getPendingSubmissionDocs(){
    try {
      let response = await Axios.post(BASE_API_URL+'broker/getPendingSubmissionDocs',
      {
        loanTxnId: this.loanTxnId
      })
      this.approvedDocuments = response.data;
      this.pendingSubLoader = false;
      this.showDocuments = true;
      this.batchToLenderDocs = this.approvedDocuments.filter(d=>d.hasOwnProperty('batchToLender'));
      this.batchToLenderDocs.sort(function(a,b){
        let dateA:any = new Date(a.updatedOn);
        let dateB:any = new Date(b.updatedOn);
        return dateB - dateA
      });
      this.pendingSubmissionDocs = this.approvedDocuments.filter(d=>!d.hasOwnProperty('batchToLender'));
      this.pendingSubmissionDocs.sort(function(a,b){
       let dateA:any = new Date(a.updatedOn);
       let dateB:any = new Date(b.updatedOn);
       return dateB - dateA
      });
    } catch (error) {
      console.log(error);

    }
  }

  public displayDocument(fileHistory){
    let files = fileHistory[fileHistory.length-1].files;
    let commentData =fileHistory[fileHistory.length-1].commentPath?this.borrower.borrowerData.comment[fileHistory[fileHistory.length-1].commentPath]:null;
     this.$refs.dfl["setInitialValuesForAttachmentsAndComments"](
      commentData?commentData.comment:'',
      files,
      null,
      null,
      fileHistory[fileHistory.length-1]
    );
  }
  public downloadDocument(fileHistory){
    let files = fileHistory[fileHistory.length-1].files;
    files.forEach(e=> {
      files.path = e.path;
    });
    this.$refs.dfl["downloadCurrentReviewDocument"](
        files
      );
  }

  public async getBorrowerCommunicationHistory(){
    try {
      let response = await Axios.post(BASE_API_URL+'broker/getBorrowerCommunicationHistory',
      {
        loanTxnId: this.loanTxnId
      });
      this.borrower.borrowerData['communicationHistory'] = response.data;
      this.borrower= JSON.parse(JSON.stringify(this.borrower));
      this.communicationdotLoader = false;

    } catch (error) {
      console.log(error);

    }
  }

  public async getWesignRequiredDocuments() {
    const loanTxnId = this.$route.query.id;

    this.brokerDisclosuresDocuments = [];
    this.additionalDocuments = [];
    this.approvedBrokerDisclosuresDocuments = [];
    this.approvedAdditionalDocuments = [];

    const result = await Axios.get(
      `${BASE_API_URL}disclosure/borrowerWesignRequiredDocuments`,
      {
        params: { loanTxnId }
      }
    );

    const brokerDisclosuresDocuments = result.data.filter(document => ['Broker Disclosure'].includes(document.type));
    this.everyBrokerDisclosureIsSigned = brokerDisclosuresDocuments.every(document => document.allFieldsSigned);

    if (this.everyBrokerDisclosureIsSigned) {
      this.approvedBrokerDisclosuresDocuments = brokerDisclosuresDocuments;
    } else {
      this.brokerDisclosuresDocuments = brokerDisclosuresDocuments;
    }

    result.data
      .filter(document => ['Additional Disclosure', 'Lender Disclosure'].includes(document.type))
      .forEach(document => {
        (document.fileHistory.length == 0 || document.fileHistory[document.fileHistory.length - 1].status !== 'Approved')
          ? this.additionalDocuments.push(document)
          : this.approvedAdditionalDocuments.push(document);
      });

    this.brokerDisclosuresDocumentsStatus = this.getBrokerDisclosuresDocumentsStatus();
      
  }

  public getBrokerDisclosuresDocumentsStatus() {

    let status;
    const brokerSignPending = this.brokerDisclosuresDocuments.some(document => this.brokerSignPending(document))

    if (this.everyBrokerDisclosureIsSigned) {
      status = '--signed';
    } else if (brokerSignPending) {
      status = '--current-user-pending';
    } else {
      status = '--pending'
    }

    return status;
  }

  public getWesignStatusLabel(doc) {

    try {
      if (doc.allFieldsSigned) { return 'Signed'; }
      if (this.brokerSignPending(doc)) { return 'Your signature required'; }
      if (!this.brokerSignPending(doc) && !doc.allFieldsSigned) { return 'Pending signatures'; }
    } catch (e) {
      return '';
    }

  }

  public toggleBrokerDisclosuresDocumentsVisibility() {
    this.showBrokerDisclosuresDocuments = !this.showBrokerDisclosuresDocuments;
  }

  public brokerSignPending(document) {
    return document.allPendingSignUserIds.includes(this.$userId);
  }

  public async downloadOriginalBrokerDisclosures($event) {
    this.downloadingBrokerDisclosure = true;
    $event.stopPropagation();

    const loanTxnId = this.borrower.pipelineData.loanTxnId;

    try {
      const response = await Axios.post(
        BASE_API_URL + "disclosure/downloadOriginalBrokerDisclosures",
        { loanTxnId },
        { responseType: "blob" }
      );

      saveAs(response.data, `${loanTxnId}-Original-Broker-Disclosures.zip`);

      this.downloadingBrokerDisclosure = false;
    } catch (error) {
      this.downloadingBrokerDisclosure = false;
    }
  }

  public async downloadSignedBrokerDisclosures($event) {
    this.downloadingBrokerDisclosure = true;
    $event.stopPropagation();

    const loanTxnId = this.borrower.pipelineData.loanTxnId;

    try {
      const response = await Axios.post(
        BASE_API_URL + "disclosure/downloadSignedBrokerDisclosures",
        { loanTxnId },
        { responseType: "blob" }
      );

      saveAs(response.data, `${loanTxnId}-Signed-Broker-Disclosures.zip`);

      this.downloadingBrokerDisclosure = false;
    } catch (error) {
      this.downloadingBrokerDisclosure = false;
    }
  }

  get getWetsignBorrowers() {

    const disclosures = this.everyBrokerDisclosureIsSigned
      ? this.approvedBrokerDisclosuresDocuments
      : this.brokerDisclosuresDocuments;

    const disclosuresWithWetsignatures = disclosures.filter(disclosure => _.get(disclosure, 'fileHistoryForBorrower', []).length);
    const borrowers = _.flatMap(disclosuresWithWetsignatures, disclosure => _.get(disclosure, 'fileHistoryForBorrower', []).map(item => _.get(item, 'borrowerId', '')));

    return borrowers.map(borrowerId => {
      const { firstName, lastName } = this.borrower.pipelineData.borrowerInfo.find(borrowerItem => _.get(borrowerItem, 'userId', '') === borrowerId);

      if (!firstName && !lastName) { return null; }

      return {
        borrowerId,
        name: `${firstName} ${lastName}`
      };
    }).filter(Boolean);
  }

  public async downloadWetsignDisclosures($event, borrower) {
    this.downloadingBrokerDisclosure = true;
    $event.stopPropagation();

    try {
      const loanTxnId = this.borrower.pipelineData.loanTxnId;

      const response = await Axios.post(
        BASE_API_URL + "disclosure/downloadSignedBrokerDisclosures",
        { loanTxnId, borrowerId: _.get(borrower, 'borrowerId', null) },
        { responseType: "blob" }
      );

      saveAs(response.data, `${loanTxnId}-${borrower.name}-Broker-Disclosures.zip`);

      this.downloadingBrokerDisclosure = false;
    } catch (error) {
      this.downloadingBrokerDisclosure = false;
    }
  }

  public openFormsPage(doc = null) {
    this.$router.push(this.$getCurrentUserTypePath("disclosures") + `?id=${this.$route.query.id}`);
  }

  public previewUploadFile(fileInfo) {
    let index = this.file.findIndex(f => f.name == fileInfo.name && f.size == fileInfo.size);
    if (index < 0 || this.isFileHasUploadError(this.file[index].name)) return;
    this.showAttachedFiles(index)
  }

  public isFileHasUploadError(fileName) {
    let fileInfo = this.file.find(f => f.name == fileName);
    if (!fileInfo) return;
    if (_.get(fileInfo, 'errorMessage')) return true;
  }

  setFileInput() {
    this.fileInput = document.documentElement.querySelector('.vue-file-agent .file-input');
  }

  public onPaperclipHandler() {
    if (!this.fileInput) {
      this.setFileInput();
    }

    this.fileInput?.click();
  }

  public setFileForDelete(file) {
    this.fileForDelete = file
  }

  public onDragToRowEnterHandler(e) {
    this.enterTargetRow = e.target;
    this.visibleInputId = e.currentTarget.id;
  }

  public onDragToRowLeaveHandler(e) {
    if (this.enterTargetRow === e.target) {
      this.visibleInputId = null;
    }
  }

  async mounted() {
    this.setFileInput();
    EventBus.$on('file-for-delete', this.setFileForDelete)
    this.loanTxnId = this.$route.query.id;
    await this.getBorrowerLoanDetails();
    await this.getPendingSubmissionDocs();
    await this.getWesignRequiredDocuments();
    await this.fetchBorrowerProgressTimeline(this.loanTxnId);
  }

  beforeDestroy() {
    EventBus.$off('file-for-delete', this.setFileForDelete)
  }
}
